import React, { useState, useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Avatar, Button, Input, Drawer, Menu, Row, Col, Modal } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link, NavLink, useHistory } from "react-router-dom";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import loadable from "@loadable/component";
import classNames from "classnames";

// components
import { SocialIcon } from "../../app-shared/components";
const HeaderDropdown = loadable(() => import("./HeaderDropdown"));
const TopBarLink = loadable(() => import("./TopBarLink"));

// api
import storefront from "../../api/storefront";

// redux
import { logoutRequest, openLoginModalRequest, closeNotificationModalRequest } from "../../redux/auth/authActions";
import { getAuthorizedUserProfileRequest } from "../../redux/profiles/profilesActions";
import { getCategoriesWithoutPaginationRequest } from "../../redux/home/homeActions";
import { getListOfAllPeriodsRequest } from "../../redux/periods/periodsActions";
import { getAllNotificationsRequest } from "../../redux/notifications/notificationsActions";
import {
  createNewSearchEmailPreferenceRequest,
  clearSearchPreferencesValue,
  openNotificationDrawer as openNotificationDrawerAction,
  closeNotificationDrawer as closeNotificationDrawerAction,
} from "../../redux/general/generalActions";

// hooks
import useAlgoliaBulkSearch, { buildSearchQuery } from "../../hooks/useAlgoliaBulkSearch";
import useServerSafeEffect from "../../hooks/useServerSafeEffect";
import useDimensions from "../../hooks/useDimensions";
import useQueryParams from "../../hooks/useQueryParams";

// helpers
import { formatPrice, fullyDecodeURL } from "../../helpers";
import { NO_IMAGE_URL, TOP_BAR_CATEGORIES } from "../../constants";

// assets
import HeartIcon from "../Icons/Heart";
import BellBadgeOutlineIcon from "../Icons/BellBadgeOutlineIcon";
import BellOutlineIcon from "../Icons/BellOutlineIcon";

// styles
import "../../assets/styles/shared/header.scss";

const SEARCH_RESULTS_PATH = "/search-results";


const Header = ({
  isAuthenticated,
  logout,
  openLoginModal,
  getAuthorizedUserProfile,
  authorizedUserProfile,
  getCategoriesWithoutPagination,
  allCategories,
  getAllPeriods,
  allPeriods,
  closeNotificationModal,
  searchPreference,
  createNewSearchEmailPreference,
  clearStoredSearchPreferencesValue,
  notificationDrawerOpened,
  openNotificationDrawer,
  closeNotificationDrawer,
  getAllNotifications,
}) => {
  const [cursor, setCursor] = useState(-1);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchFocused, setSearchFocused] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [activeSearchElementRef, setActiveSearchElementRef] = useState(null);
  const [firstActiveSearchElementRef, setFirstActiveSearchElementRef] = useState(null);
  const [selectedTopBarCategory, setSelectedTopBarCategory] = useState({});
  const [searchedItemsLength, setSearchedItemsLength] = useState(0);

  const wrapperRef = useRef(null);
  const mobileWrapperRef = useRef(null);
  const searchResultsRef = useRef(null);
  const headerDropdownRef = useRef(null);

  // Search inputs
  const desktopInputRef = useRef();
  const mobileInputRef = useRef();

  const [{ search = "" }, setQueryParams] = useQueryParams();
  const [headerRef, { width }] = useDimensions({ liveMeasure: true });
  const history = useHistory();

  const showNotifications = isAuthenticated && authorizedUserProfile.role === "DEALER";
  const userFirstName = get(authorizedUserProfile, "firstName", "");

  const isSmsModalVisible = !isEmpty(authorizedUserProfile) && !authorizedUserProfile.smsNotification &&
    authorizedUserProfile.role === "DEALER";

  const { results, loading, fetch: fetchSearchResults } = useAlgoliaBulkSearch({ queries: buildSearchQuery("") });

  // Effects
  useEffect(() => {
    if (!results || !searchFocused) {
      return;
    }

    const {
      Item_query_suggestions
    } = results;

    const Item = results.Item;
    const Category = results.Category;
    const Period = results.Period;
    const CatalogElement = results.CatalogElement;
    const Artisan = results.Artisan;
    const Dealer = results.Dealer;

    const materialsList = CatalogElement?.hits?.filter(({ catalog_title }) => catalog_title === "Material") || [];
    const originsList = CatalogElement?.hits?.filter(({ catalog_title }) => catalog_title === "Origin") || [];

    const searchResults = [];
    const orderedSearchResults = {
      Suggestion: Item_query_suggestions,
      Category,
      Period,
      Material: { hits: materialsList.map(item => ({ ...item, searchType: "Material" })) },
      Origin: { hits: originsList.map(item => ({ ...item, searchType: "Origin" })) },
      Artisan,
      Dealer,
      Item
    };

    Object.keys(orderedSearchResults).forEach(key => {
      if (orderedSearchResults[key]) {
        const resultTypeList = orderedSearchResults[key].hits?.map(item => {
          return ({
            ...item,
            searchType: key,
          });
        }) ?? [];

        searchResults.push(...resultTypeList);
      }
    });

    setSearchedItemsLength(Item.hits?.length || 0);
    setSearchOptions(searchResults);
  }, [results]);

  useEffect(() => {
    if (activeSearchElementRef) {
      activeSearchElementRef.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }

    if (cursor === -1 && firstActiveSearchElementRef) {
      firstActiveSearchElementRef.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  }, [cursor]);

  // Listen to history pathname changes
  useEffect(() => {
    return history.listen((currentLocation, action) => {
      // Clear "search" query param on POP action
      const query = new URLSearchParams(currentLocation.search);
      if (action === "POP" && query.has("search") && currentLocation.pathname !== SEARCH_RESULTS_PATH) {
        query.delete("search");
        history.replace({ pathname: currentLocation.pathname, search: `?${query.toString()}` })
      }
    })
  }, [mobileMenuOpen]);

  useEffect(() => {
    // Hide result if clicked on outside of element
    function handleClickOutside(event) {
      if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
        return;
      }
      if (mobileWrapperRef.current && mobileWrapperRef.current.contains(event.target)) {
        return;
      }

      setSearchFocused(false);
    }

    // Bind the event listener
    document.addEventListener("click", handleClickOutside);

    // Unbind the event listener on clean up
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [wrapperRef, mobileWrapperRef]);

  useEffect(() => {
    function clickOutsideOfHeaderDropdown(event) {
      if (headerDropdownRef.current &&
        headerDropdownRef.current.contains(event.target) &&
        event.target.innerHTML !== "All Antiques"
      ) {
        return;
      }

      setIsDropdownVisible(false)
    }

    document.addEventListener("click", clickOutsideOfHeaderDropdown);

    return () => {
      document.removeEventListener("click", clickOutsideOfHeaderDropdown);
    };
  }, [headerDropdownRef]);

  useEffect(() => {
    isAuthenticated && getAuthorizedUserProfile();
  }, [isAuthenticated]);

  useServerSafeEffect(() => {
    if (!allCategories || !allCategories.length) {
      getCategoriesWithoutPagination();
    }
    if (!allPeriods || !allPeriods.length) {
      getAllPeriods({ pathname: history.location.pathname });
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && !isEmpty(searchPreference)) {
      createNewSearchEmailPreference(searchPreference);
      clearStoredSearchPreferencesValue();
    }
  }, [searchPreference, isAuthenticated]);

  // Debounced search via API
  const onSearch = useCallback(debounce(value => {

    /* TODO: LANT-4974 */
    // if (history.location.pathname === SEARCH_RESULTS_PATH) {
    //
    //   return;
    // }

    fetchSearchResults(buildSearchQuery(fullyDecodeURL(value)));
  }, 550), []);

  // Load notifications
  useEffect(() => {
    if (showNotifications) {
      getAllNotifications()
    }
  }, [showNotifications])

  const getCurrentDateAndTime = () => {
    const date = new Date();

    const currentDate = date.toLocaleString().slice(0, 10);
    const currentTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    return `${currentDate} ${currentTime}`;
  }

  // Methods

  const handleRedirect = (url) => {
    history.replace(`${url}?search=${search}`);
    setSearchFocused(false);
    storefront.createSearchQuery({ query: search });
  };

  const handleHeartIconClick = () => {
    if (authorizedUserProfile.role === "WEBSITE_VISITOR") {
      return history.push({ pathname: "/profile/my-favorites" });
    }
    if (authorizedUserProfile.role === "DEALER") {
      return history.push({ pathname: "/profile/favorites/items" });
    }

    return null;
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 38 && cursor >= -1) {
      e.preventDefault();
      setCursor(cursor - 1);
    }

    if (e.keyCode === 40 && cursor <= searchOptions.length) {
      setCursor(cursor + 1);
    }
  };

  const handleSelectSearchElement = (index) => {
    //eslint-disable-next-line
    let urlToRedirect;

    const cursorIndex = typeof index !== "undefined" ? index : cursor;

    if (searchOptions[cursorIndex] && searchOptions[cursorIndex].searchType) {
      if (searchOptions[cursorIndex].searchType === "Suggestion") {
        history.replace(`/search-results?search=${searchOptions[cursorIndex].query}`);
        history.go(0);

        return;
      }

      if (searchOptions[cursorIndex].searchType === "categories") {
        urlToRedirect = `/${searchOptions[cursorIndex].url}`;
      }
      if (searchOptions[cursorIndex].searchType === "period") {
        urlToRedirect = `/period/${searchOptions[cursorIndex].url}`;
      }
      if (searchOptions[cursorIndex].searchType === "items") {
        urlToRedirect = `/items/listings/${searchOptions[cursorIndex].url}`;
      }
      if (searchOptions[cursorIndex].searchType === "dealers") {
        urlToRedirect = `/directory/dealers/${searchOptions[cursorIndex].url}`;
      }
      if (searchOptions[cursorIndex].searchType === "artisans") {
        urlToRedirect = `/knowledge/artisan/${searchOptions[cursorIndex].url}`;
      }
      if (searchOptions[cursorIndex].searchType === "origin") {
        urlToRedirect = `/knowledge/origin/${searchOptions[cursorIndex].url}`;
      }
      if (searchOptions[cursorIndex].searchType === "material") {
        urlToRedirect = `/knowledge/material/${searchOptions[cursorIndex].url}`;
      }
    }

    history.replace(`${urlToRedirect || "search-results"}?search=${search}`);

    setCursor(-1);
    setSearchFocused(false);
  };

  const handleFocusSearchField = () => {
    setSearchFocused(true);

    if (isEmpty(searchOptions) && search) {
      fetchSearchResults(buildSearchQuery(search));
    }
  };

  const handleUnFocus = ref => {
    if (ref.current) {
      ref.current.blur();
    }
  };

  const handleSearch = ref => {
    if (!search) {
      history.push({ pathname: "/antiques" });
    } else if (cursor < 0) {
      if (history.location.pathname === SEARCH_RESULTS_PATH) {
        history.go(0);
      } else {
        history.push({ pathname: SEARCH_RESULTS_PATH, search: `?search=${search}` });
      }
    } else {
      handleSelectSearchElement();
    }

    setSearchFocused(false);

    handleUnFocus(ref);
  };

  const handleChange = value => {
    const encodedValueForUrl = encodeURIComponent(fullyDecodeURL(value));
    if (!searchFocused) {
      setSearchFocused(true);
    }

    onSearch(value);
    setQueryParams({ search: encodedValueForUrl, page: 1, title: null }, true);
  };

  const handleTriggerNotificationModal = (shouldRedirect = false) => {
    const dataToSend = {
      smsNotification: true
    };

    closeNotificationModal(dataToSend);

    if (shouldRedirect) {
      setTimeout(() => history.push({ pathname: "/profile/paypal/settings" }), 1000);
    }
  };

  const renderAvatar = () => {
    const userRole = authorizedUserProfile && get(authorizedUserProfile, "role", "WEBSITE_VISITOR");
    if (userRole === "WEBSITE_VISITOR" || userRole === "TRADE") {
      const userImg = authorizedUserProfile && get(authorizedUserProfile, "image.url");

      return userImg ? <img src={userImg} alt="User profile photo" /> : <UserOutlined />;
    }
    if (userRole === "DEALER") {
      const textLogoUrl = authorizedUserProfile && get(authorizedUserProfile, "textLogoUrl", "");
      const shouldUseTextLogo = authorizedUserProfile && get(authorizedUserProfile, "shouldUseTextLogo", "");
      const dealerImg = (authorizedUserProfile && get(authorizedUserProfile, "logo")) || shouldUseTextLogo;

      return dealerImg ? (
        <img src={shouldUseTextLogo ? textLogoUrl : dealerImg} alt="Dealer profile photo" />
      ) : (
        <UserOutlined />
      );
    }

    return <UserOutlined />
  };

  const authContent = isAuthenticated ? (
    <div className="header--auth-control">
      <Link to="/profile/home">
        <Avatar className="header--avatar" shape="circle" size={40} icon={renderAvatar()} />
      </Link>
      <p
        className="header--auth-name"
        onClick={() => {
          history.push({ pathname: "/profile/home" });
          setMobileMenuOpen(false);
        }}
      >
        {userFirstName}
      </p>
      <Button
        size="large"
        className="header--auth-button--logout"
        onClick={() => {
          logout();
          setMobileMenuOpen(false);
        }}
      >
        Log out
      </Button>
    </div>
  ) : (
    <div className="header--auth-control">
      {history.location.pathname !== "/favorites/items" && (
        <Button
          href="/registration"
          className="header--auth-link"
          onClick={(e) => {
            e.preventDefault();
            history.push({ pathname: "/registration" });
            setMobileMenuOpen(false);
          }}
        >
          Register
        </Button>
      )}
      <Button
        className="header--auth-button"
        size="large"
        onClick={() => {
          openLoginModal();
          setMobileMenuOpen(false);
        }}
      >
        Log in
      </Button>
    </div>
  );

  const renderSearchResult = (result) => {
    return result.map((item, index) => {
      const activeItemClass = cursor === index ? "search-result-active-item" : null
      const labelColors = {
        Category: "#CB6157",
        Period: "#3A98D9",
        Material: "#C0C0C0",
        Origin: "#7AD7C4",
        Artisan: "#F7A327",
        Dealer: "#990099",
        HomeAndLiving: "#4b0082",
      };
      const urlToRedirectTo = (item.searchType === "Category") ?
        `/${item.url}` :
        (item.searchType === "Period") ?
          `/period/${item.url}` :
          (item.searchType === "homeAndLiving")
            ? `/${item.url}`
            : (item.searchType === "Material") ?
              `/knowledge/material/${item.url}` :
              `/knowledge/origin/${item.url}`;
      let itemStatus = "";
      if (item.item_is_reserved) {
        itemStatus = "Reserved";
      } else if (item.make_this_price_poa) {
        itemStatus = "POA"
      }

      switch (item.searchType) {
        case "Item":
          return (
            <div
              className={`header--search-result-row header--search-result-row_item ${activeItemClass}`}
              key={index}
              onClick={() => {
                handleRedirect(`/items/listings/${item.url}`);
              }}
              tabIndex={index}
              ref={ref => {
                index === cursor && setActiveSearchElementRef(ref)
                index === 0 && setFirstActiveSearchElementRef(ref);
              }}
            >
              <Row
                type="flex"
                style={{ alignItems: "flex-start" }}
                className="item-row"
              >
                <Col xxl={3} xl={3} lg={3} md={3} sm={7} xs={7}>
                  <img src={item.image || NO_IMAGE_URL} alt="logo" />
                </Col>
                <Col xxl={21} xl={21} lg={21} md={21} sm={17} xs={17}>
                  <div className="header--search-result-row--item-info">
                    <h3 className="header--search-result-row--title">{item.title}</h3>
                    <p className="header--search-result-row--description">{item.description}</p>
                    <p>
                      {itemStatus ? itemStatus : item.make_this_price_poa ? "POA" : `£ ${formatPrice(item.price)}`}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          );

        case "Category":
        case "Period":
        case "Material":
        case "Origin":
        case "HomeAndLiving":
          return (
            <div
              className={`header--search-result-row ${activeItemClass}`}
              key={index}
              onClick={() => {
                handleRedirect(urlToRedirectTo);
              }}
              tabIndex={index}
              ref={ref => {
                index === cursor && setActiveSearchElementRef(ref);
                index === 0 && setFirstActiveSearchElementRef(ref);
              }}
            >
              <Row
                type="flex"
                style={{ alignItems: "center", padding: "15px" }}
                className="item-row"
              >
                <Col xl={18} lg={17} md={18} sm={18} xs={14} style={{ padding: 0 }}>
                  <span className="header--search-result-row--title">
                    <span>{item.title}</span>
                    {!!item.itemsCount && (
                      <span className="header--search-result-row--title-items-count">({item.itemsCount})</span>
                    )}
                  </span>
                </Col>
                <Col xl={6} lg={7} md={6} sm={6} xs={10} style={{ padding: 0 }}>
                  <span
                    className="header--search-result-row--search-type"
                    style={{ background: labelColors[item.searchType] }}
                  >
                    {item.searchType.replace("And", " & ").toLowerCase()}
                  </span>
                </Col>
              </Row>
            </div>
          );

        case "Artisan":
          return (
            <div
              className={`header--search-result-row ${activeItemClass}`}
              key={index}
              onClick={() => {
                handleRedirect(`/knowledge/artisan/${item.url}`);
              }}
              tabIndex={index}
              ref={ref => {
                index === cursor && setActiveSearchElementRef(ref);
                index === 0 && setFirstActiveSearchElementRef(ref);
              }}
            >
              <Row
                type="flex"
                style={{ alignItems: "center", padding: "15px" }}
                className="item-row"
              >
                <Col xxl={20} xl={18} lg={17} md={18} sm={18} xs={14} style={{ padding: 0 }}>
                  <span
                    className="header--search-result-row--title">{item.name}</span>
                </Col>
                <Col xxl={4} xl={6} lg={7} md={6} sm={6} xs={10} style={{ padding: 0 }}>
                  <span
                    className="header--search-result-row--search-type"
                    style={{ background: labelColors[item.searchType] }}
                  >
                    {item.searchType}
                  </span>
                </Col>
              </Row>
            </div>
          );

        case "Dealer":
          return (
            <div
              className={`header--search-result-row ${activeItemClass}`}
              onClick={() => {
                handleRedirect(`/directory/dealers/${item.url}`);
              }}
              key={index}
              tabIndex={index}
              ref={ref => {
                index === cursor && setActiveSearchElementRef(ref);
                index === 0 && setFirstActiveSearchElementRef(ref);
              }}
            >
              <Row
                type="flex"
                style={{ alignItems: "center", padding: "15px" }}
                className="item-row"
              >
                <Col xxl={20} xl={18} lg={17} md={18} sm={18} xs={14} style={{ padding: 0 }}>
                  <span
                    className="header--search-result-row--title">{item.businessName}</span>
                </Col>
                <Col xxl={4} xl={6} lg={7} md={6} sm={6} xs={10} style={{ padding: 0 }}>
                  <span
                    className="header--search-result-row--search-type"
                    style={{ background: labelColors[item.searchType] }}
                  >
                    {item.searchType}
                  </span>
                </Col>
              </Row>
            </div>
          );

        case "Suggestion":
          return (
            <Link
              to={`/search-results?search=${item.query}`}
              className={`header--search-result-row header--search-result-row_suggestion ${activeItemClass}`}
              key={`${item.query}-${index}`}
              onClick={() => {
                setSearchFocused(false);
                setCursor(index);
                handleSelectSearchElement(index);
              }}
            >
              <Row
                className="item-row"
              >
                <Col xs={24} className="header--search-result-row_suggestion">
                  <span className="header--search-result-row--title">
                    {item.query}
                  </span>
                </Col>
              </Row>
            </Link>
          );

        default:
          return null;
      }
    });
  };

  const subcategories = [];
  for (const category of TOP_BAR_CATEGORIES) {
    if (category.children) {
      subcategories.push(...category.children)
    }
  }

  const renderNotificationButton = () => {
    if (!showNotifications) {
      return null;
    }

    return (
      <div
        className="header--bell-icon"
        onClick={() => {
          if (mobileMenuOpen) {
            setMobileMenuOpen(false);
          }

          openNotificationDrawer();
        }}
      >
        {authorizedUserProfile.unreadNotifications ? <BellBadgeOutlineIcon /> : <BellOutlineIcon />}
      </div>
    )
  }

  return (
    <header className="header" ref={headerRef} data-html2canvas-ignore="true" onClick={() => {
      if (notificationDrawerOpened) closeNotificationDrawer()
    }}>
      <div className="header--timestamp">
        {getCurrentDateAndTime()}
      </div>
      <div className="page-container">
        {width > 1200 ? (
          <>
            <div className="header--row header--logo-row" style={{ height: "40px" }}>
              <Link to="/" className="header--logo">
                <img
                  src={`${process.env.REACT_APP_IMAGES_URL}la-new-logo.svg`}
                  alt="LoveAntiques"
                  width={144}
                  height={40}
                />
              </Link>

              <div className="header--search xl-hidden" ref={wrapperRef}>
                <div>
                  <Input.Search
                    placeholder="Search for Items, Period, Artisans"
                    onChange={e => handleChange(e.target.value)}
                    onFocus={handleFocusSearchField}
                    size="large"
                    enterButton
                    value={fullyDecodeURL(search)}
                    onSearch={() => handleSearch(desktopInputRef)}
                    // loading={loading}
                    onKeyDown={(e) => handleKeyDown(e)}
                    ref={desktopInputRef}
                  />
                </div>
                <div className={`header--search-result ${!searchFocused ? "hidden" : ""}`} ref={searchResultsRef}>
                  {!isEmpty(searchOptions) && searchedItemsLength >= 5 && search && (
                    <Link to={`/search-results?search=${search}`}>
                      <span
                        className="header--show-more"
                        onClick={() => {
                          setSearchFocused(false)
                          if (history.location.pathname === SEARCH_RESULTS_PATH) {
                            history.go(0);
                          }
                        }}
                      >
                        Show more results...
                      </span>
                    </Link>
                  )}
                  {search && renderSearchResult(searchOptions)}
                </div>
              </div>
              <div className="header--right-block xl-hidden">
                <div className="header--socials-group">
                  <SocialIcon type="Facebook2" url="https://www.facebook.com/loveantiquescom" />
                  <SocialIcon type="Twitter" url="https://twitter.com/loveantiques" />
                  <SocialIcon type="YouTube" url="https://www.youtube.com/channel/UCzObXljtikONwkvmmhBeqJQ" />
                  <SocialIcon type="Pinterest" url="https://www.pinterest.com/loveantiquescom/" />
                  <SocialIcon type="Instagram" url="https://www.instagram.com/loveantiques_com/" />
                </div>
                <div className="header--favorites-and-notifications">
                  <Link
                    to={isAuthenticated ? "/profile/favorites/items" : "/favorites/items"}
                    className="header--heart-icon"
                    aria-label="Redirect to favorite items"
                  >
                    <HeartIcon onClick={handleHeartIconClick} />
                  </Link>
                  {renderNotificationButton()}
                </div>
                {authContent}
              </div>
            </div>

            <div className="header--row xl-hidden">
              <div className="header--hidden-categories-links">
                <Link to="/knowledge">
                  Dealers and more
                </Link>
                {[...TOP_BAR_CATEGORIES, ...subcategories].map(category => {
                  return (
                    <Link to={category.url} key={category.id}>
                      {category.title}
                    </Link>
                  )
                })}
              </div>
              <div
                ref={headerDropdownRef}
                className="megamenu"
                onClick={() => setIsDropdownVisible(true)}
              >
                {TOP_BAR_CATEGORIES.map(category => {
                  return (
                    <TopBarLink
                      key={category.id}
                      category={category}
                      setSelectedTopBarCategory={setSelectedTopBarCategory}
                    />
                  )
                })}

                {selectedTopBarCategory.title && !["All Antiques", "Directory"].includes(selectedTopBarCategory.title) && (
                  <div
                    className={classNames(
                      "megamenu-dropdown-wrapper",
                      isDropdownVisible ? "shown" : "hidden",

                      /*TODO(LANT-5450): Change top navigation*/
                      // selectedTopBarCategory.title === "Directory and more" && "directory"
                      selectedTopBarCategory.title === "Dealers and more" && "directory"
                    )}
                  >
                    <HeaderDropdown
                      category={selectedTopBarCategory}
                      setDropdownVisible={setIsDropdownVisible}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="header--mobile-search" ref={mobileWrapperRef}>
              <div className="header--mobile-search-logo-container">
                <Link to="/">
                  <img
                    src={`${process.env.REACT_APP_IMAGES_URL}la-new-logo-large.svg`}
                    alt="LoveAntiques"
                    width={294}
                    height={38}
                  />
                </Link>
              </div>
              <div className="header--mobile-search-container">
                <Input.Search
                  placeholder="Search for Items, Period, Artisans"
                  onChange={e => handleChange(e.target.value)}
                  onFocus={handleFocusSearchField}
                  size="large"
                  enterButton
                  // loading={loading}
                  value={fullyDecodeURL(search)}
                  onSearch={() => handleSearch(mobileInputRef)}
                  ref={mobileInputRef}
                  className="header--mobile-search-container--search"
                />
                <div className="header--burger-menu-container">
                  <Button
                    className="header--mobile-menu-button xl-visible"
                    size="large"
                    aria-label="Mobile menu button"
                    onClick={() => setMobileMenuOpen(true)}
                  >
                    <span className="header--mobile-menu-icon" />
                    <span className="header--mobile-menu-icon" />
                    <span className="header--mobile-menu-icon" />
                  </Button>
                </div>
              </div>

              <div className={`header--search-result ${!searchFocused ? "hidden" : ""}`} ref={searchResultsRef}>
                {!isEmpty(searchOptions) && searchedItemsLength >= 5 && search && (
                  <Link to={`/search-results?search=${search}`}>
                    <span
                      className="header--show-more"
                      onClick={() => setSearchFocused(false)}
                    >
                      Show more results...
                    </span>
                  </Link>
                )}
                {width <= 1200 && search && renderSearchResult(searchOptions)}
              </div>
            </div>

            <Drawer
              className="xl-visible"
              open={mobileMenuOpen}
              onClose={() => setMobileMenuOpen(false)}
              placement="right"
              closable={false}
            >
              <div className="header--mobile-menu">
                <div className="header--right-block">
                  {authContent}
                </div>
                {isAuthenticated ? (
                  <div className="header--mobile-menu-fav-and-notifications">
                    <Link to="/profile/favorites/items" className="header--heart-icon">
                      <HeartIcon
                        onClick={() => {
                          handleHeartIconClick();
                          setMobileMenuOpen(false);
                        }}
                      />
                    </Link>
                    {renderNotificationButton()}
                  </div>
                ) : (
                  <div className="header--mobile-menu-fav-and-notifications">
                    <Link to="/favorites/items" className="header--heart-icon">
                      <HeartIcon
                        onClick={() => {
                          setMobileMenuOpen(false);
                        }}
                      />
                    </Link>
                  </div>
                )}
                <Menu
                  mode="inline"
                  inlineIndent={10}
                  defaultOpenKeys={["antiques"]}
                >
                  <Menu.SubMenu
                    key="antiques"
                    title={<div className="header--link drawer mobile">Categories</div>}
                    icon={null}
                  >
                    <Menu.Item key="all-antiques">
                      <NavLink
                        to="/antiques"
                        className="header--link mobile"
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        All Antiques
                      </NavLink>
                    </Menu.Item>
                    {allCategories.map(category => {
                      return (
                        <Menu.Item key={category.id}>
                          <NavLink
                            to={`/${category.url}`}
                            className="header--link mobile"
                            onClick={() => setMobileMenuOpen(false)}
                          >
                            {category.title}
                          </NavLink>
                          {category.itemsCount}
                        </Menu.Item>
                      )
                    })}
                  </Menu.SubMenu>
                  <Menu.Item key="home-and-living">
                    <NavLink
                      to="/home-and-living"
                      className="header--link drawer mobile"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      Home & Living
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="directory">
                    {/*TODO(LANT-5450): Change top navigation*/}
                    <NavLink
                      to="/directory/dealers"
                      className="header--link drawer mobile"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      Dealers
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="blog">
                    <NavLink
                      to="/blog"
                      className="header--link drawer mobile"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      Blog
                    </NavLink>
                  </Menu.Item>
                </Menu>
              </div>
              {!isAuthenticated && (
                <div className="header--mobile-menu-footer">
                  <Link to="/registration/collector" onClick={() => setMobileMenuOpen(false)}>
                    <Button className="header--mobile-menu-footer-btn">
                      Join as Collector
                    </Button>
                  </Link>
                  <Link to="/registration/dealers" onClick={() => setMobileMenuOpen(false)}>
                    <Button className="header--mobile-menu-footer-btn">
                      Join as Dealer
                    </Button>
                  </Link>
                  <Link to="/registration/trader" onClick={() => setMobileMenuOpen(false)}>
                    <Button className="header--mobile-menu-footer-btn">
                      Join Our Business Directory
                    </Button>
                  </Link>
                </div>
              )}
            </Drawer>
          </>
        )}

        <Modal
          centered
          onOk={() => handleTriggerNotificationModal(true)}
          onCancel={() => handleTriggerNotificationModal()}
          className="sms-notification-modal"
          closable={false}
          open={isSmsModalVisible}
        >
          <p>Please enable PayPal to be able to receive online payments.</p>
        </Modal>
      </div>
    </header>
  );
};

Header.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  openLoginModal: PropTypes.func.isRequired,
  getAuthorizedUserProfile: PropTypes.func.isRequired,
  authorizedUserProfile: PropTypes.object.isRequired,
  mostPopular: PropTypes.array.isRequired,
  allPeriods: PropTypes.array.isRequired,
  getCategoriesWithoutPagination: PropTypes.func.isRequired,
  allCategories: PropTypes.array.isRequired,
  getAllPeriods: PropTypes.func.isRequired,
  closeNotificationModal: PropTypes.func.isRequired,
  searchPreference: PropTypes.object.isRequired,
  createNewSearchEmailPreference: PropTypes.func.isRequired,
  clearStoredSearchPreferencesValue: PropTypes.func.isRequired
};

export default connect(
  state => ({
    isAuthenticated: state.auth.isAuthenticated,
    authorizedUserProfile: state.auth.authorizedUserProfile,
    mostPopular: state.home.mostPopular,
    allCategories: state.home.allCategories,
    allPeriods: state.periods.allPeriods,
    searchPreference: state.general.searchPreference,
    notificationDrawerOpened: state.general.notificationDrawerOpened,
  }),
  dispatch => ({
    logout: () => dispatch(logoutRequest()),
    openLoginModal: () => dispatch(openLoginModalRequest()),
    getAuthorizedUserProfile: (data) => dispatch(getAuthorizedUserProfileRequest(data)),
    getCategoriesWithoutPagination: () => dispatch(getCategoriesWithoutPaginationRequest()),
    getAllPeriods: location => dispatch(getListOfAllPeriodsRequest(location)),
    closeNotificationModal: (data) => dispatch(closeNotificationModalRequest(data)),
    createNewSearchEmailPreference: (data) => dispatch(createNewSearchEmailPreferenceRequest(data)),
    clearStoredSearchPreferencesValue: () => dispatch(clearSearchPreferencesValue()),
    openNotificationDrawer: () => dispatch(openNotificationDrawerAction()),
    closeNotificationDrawer: () => dispatch(closeNotificationDrawerAction()),
    getAllNotifications: () => dispatch(getAllNotificationsRequest()),
  })
)(Header);
