import { useState, useCallback } from "react";

// helpers
import storefront from "../api/storefront";


function useAlgoliaBulkSearch({ queries }) {
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchSearchResults = useCallback((searchQueries) => {
    if (!searchQueries || loading) {
      return;
    }

    setLoading(true);
    storefront.algoliaSearch({ requests: searchQueries })
      .then(resp => {
        const formattedResp = {};

        [...queries, { modelName: "Item_query_suggestions" }].forEach(({ modelName, params }) => {
          const respSingleItem = resp.results.find(({ index }) => index === modelName ||
            index === `${modelName}_staging` || index === `${modelName}_production`);

          if (respSingleItem && params?.hitsPerPage) {
            formattedResp[modelName] = {
              ...respSingleItem,
              hits: respSingleItem?.hits?.slice(0, params.hitsPerPage) || []
            };
          } else {
            formattedResp[modelName] = respSingleItem || {};
          }
        });

        setResults(formattedResp);
      })
      .catch(e => setError(e))
      .finally(() => setLoading(false));
  }, []);


  return ({
    results,
    error,
    loading,
    fetch: fetchSearchResults
  });
}

export const buildSearchQuery = (searchWord = "") => {
  return [
    {
      modelName: "Category",
      appName: "categories",
      query: searchWord,
      params: {
        hitsPerPage: 3,
      },
    }, {
      modelName: "Item",
      appName: "items",
      query: searchWord,
      params: {
        hitsPerPage: 5,
      },
    }, {
      modelName: "Artisan",
      appName: "artisans",
      query: searchWord,
      params: {
        hitsPerPage: 3
      }
    }, {
      modelName: "Period",
      appName: "periods",
      query: searchWord,
      params: {
        hitsPerPage: 3
      }
    }, {
      modelName: "CatalogElement",
      appName: "catalogs",
      query: searchWord,
      params: {
        hitsPerPage: 3
      }
    }, {
      modelName: "Dealer",
      appName: "dealers",
      query: searchWord,
      params: {
        hitsPerPage: 3
      }
    }
  ];
}

export default useAlgoliaBulkSearch;
